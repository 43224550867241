<script setup lang="ts">
import type { SpecSheet } from '@/types/SpecSheet';
import { register } from 'swiper/element/bundle';
import ConfirmDetail from '../Component/ConfirmDetail.vue';
import type { ApproveForm } from '~/types/Global';

// PROPS
interface Props {
    specSheet: SpecSheet;
    approved?: boolean;
}
const props = withDefaults(defineProps<Props>(), { approved: false });

// VARIABLE
const { specSheet, approved } = toRefs(props);
const { $imageUrl } = useNuxtApp();
const form = inject('form') as ApproveForm;

// ONMOUNTED
onMounted(() => {
    register();
});
</script>
<template>
    <div>
        <h3 class="text-2xl text-white dark:text-black text-center font-medium bg-primary-700 dark:bg-primary-400 rounded-t-xl p-3">ข้อมูลไซซ์และจำนวน</h3>

        <div class="relative rounded-b-xl overflow-auto">
            <Table>
                <TableBody class="bg-stone-100 dark:bg-stone-800">
                    <TableRow v-for="(group, groupI) in specSheet.amount" :key="group.name + groupI" class="text-lg dark:text-white">
                        <TableCell class="w-1/2 max-w-80 p-3">
                            <Swiper class="rounded-2xl w-full max-h-[36rem]" :navigation="true" :pagination="true" :loop="true" :centered-slides="true">
                                <SwiperSlide v-for="(img, img_i) in group.img" :key="useGenVueKey(img_i)" class="flex justify-center rounded-2xl">
                                    <img class="rounded-2xl object-contain" :src="$imageUrl(img.url)" />
                                </SwiperSlide>
                            </Swiper>
                        </TableCell>
                        <TableCell class="w-1/2">
                            <small class="text-stone-500">ชื่อกลุ่ม</small>
                            <p class="text-lg font-medium">{{ group.name }}</p>

                            <small class="text-stone-500">จำนวน</small>
                            <ul>
                                <li v-for="(size, sizeI) in group.info" :key="useGenVueKey(sizeI)">{{ size.name }} - {{ size.amount }} ตัว</li>
                            </ul>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>

        <!-- CONFIRM -->
        <ConfirmDetail v-if="!approved" v-model="form.amountlist">ฉันยืนยันว่าข้อมูลไซซ์และจำนวนถูกต้อง</ConfirmDetail>
    </div>
</template>
