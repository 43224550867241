<script setup lang="ts">
import type { SpecSheet, SpecSheetDesignGroup } from '@/types/SpecSheet';
import ConfirmDetail from '../Component/ConfirmDetail.vue';
import type { ApproveForm } from '~/types/Global';

// LAZY LOAD
const ScreenWorkV1 = defineAsyncComponent(() => import('~/components/Component/Screen/V1/ScreenWork.vue'));
const ScreenWorkV2 = defineAsyncComponent(() => import('~/components/Component/Screen/V2/ScreenWork.vue'));

// PROPS
interface Props {
    specSheet: SpecSheet;
    approved?: boolean;
}
const props = withDefaults(defineProps<Props>(), { approved: false });

// VARIABLE
const { specSheet, approved } = toRefs(props);
const form = inject('form') as ApproveForm;

// FUNCTION
function getArtwork(src: SpecSheetDesignGroup<any>) {
    if (src.artworks.length > 0) {
        return src.artworks[0];
    } else {
        return undefined;
    }
}
</script>
<template>
    <div>
        <h3 class="text-2xl text-white dark:text-black text-center font-medium bg-primary-700 dark:bg-primary-400 p-3 rounded-xl mb-3">ข้อมูลงานสกรีน</h3>

        <div class="mb-3">
            <template v-for="(screenGroup, screenGroupI) in specSheet.screenPoints" :key="screenGroup.id">
                <ScreenWorkV1 v-if="specSheet.version === 1" :screen="screenGroup" :index="screenGroupI" />

                <template v-else-if="specSheet.version === 2">
                    <div class="p-5 rounded-xl bg-stone-100 dark:bg-stone-800">
                        <p class="text-2xl font-medium dark:text-white mb-3">ชื่อลาย: {{ screenGroup.name }}</p>
                        <img :src="$imageUrl(getArtwork(screenGroup)?.url, 1000)" class="w-full mb-3 rounded-xl" />
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                            <ScreenWorkV2 v-for="(screen, screenI) in screenGroup.positions" :key="screen.id" :screen="screen" :index="screenI" />
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <!-- CONFIRM -->
        <ConfirmDetail v-if="!approved" v-model="form.screen">ฉันยืนยันว่าข้อมูลงานสกรีนถูกต้อง</ConfirmDetail>
    </div>
</template>
