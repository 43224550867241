<script setup lang="ts">
import type { SpecSheet, SpecSheetDesignGroup } from '@/types/SpecSheet';
import ConfirmDetail from '../Component/ConfirmDetail.vue';
import type { ApproveForm } from '~/types/Global';

// LAZY LOAD
const PrintWorkV1 = defineAsyncComponent(() => import('~/components/Component/Print/V1/PrintWork.vue'));
const PrintWorkV2 = defineAsyncComponent(() => import('~/components/Component/Print/V2/PrintWork.vue'));

// PROPS
interface Props {
    specSheet: SpecSheet;
    approved: boolean;
}
const props = defineProps<Props>();

// VARIABLE
const { specSheet, approved } = toRefs(props);
const form = inject('form') as ApproveForm;

// FUNCTION
function getArtwork(src: SpecSheetDesignGroup<any>) {
    if (src.artworks.length > 0) {
        return src.artworks[0];
    } else {
        return undefined;
    }
}
</script>
<template>
    <div>
        <h3 class="text-2xl text-white dark:text-black text-center font-medium bg-primary-700 dark:bg-primary-400 p-3 rounded-xl mb-3">ข้อมูลงานพิมพ์</h3>

        <div class="mb-3">
            <template v-for="(printGroup, printGroupI) in specSheet.printPoints" :key="printGroup.id">
                <PrintWorkV1 v-if="specSheet.version === 1" :print="printGroup" :index="printGroupI" />

                <template v-else-if="specSheet.version === 2">
                    <div class="p-5 rounded-xl bg-stone-100 dark:bg-stone-800">
                        <p class="text-2xl font-medium dark:text-white mb-3">ชื่อลาย: {{ printGroup.name }}</p>
                        <img :src="$imageUrl(getArtwork(printGroup)?.url, 1000)" class="w-full mb-3 rounded-xl" />
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                            <PrintWorkV2 v-for="(print, printI) in printGroup.positions" :key="print.id" :print="print" :index="printI" />
                        </div>
                    </div>
                </template>
            </template>
        </div>

        <!-- CONFIRM -->
        <ConfirmDetail v-if="!approved" v-model="form.print">ฉันยืนยันว่าข้อมูลงานพิมพ์ถูกต้อง</ConfirmDetail>
    </div>
</template>
