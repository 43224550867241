<script setup lang="ts">
import VerificationModal from '@/components/Component/VerificationModal.vue';
import LoadingScreen from '~/components/LoadingScreen.vue';
import AmountDetails from '~/components/Section/AmountDetails.vue';
import ScreenDetails from '~/components/Section/ScreenDetails.vue';
import PinDetails from '~/components/Section/PinDetails.vue';
import type { ApproveForm } from '~/types/Global';
import type { SpecSheet } from '~/types/SpecSheet';
import PrintDetails from '~/components/Section/PrintDetails.vue';

// LAZY LOAD
const Status = defineAsyncComponent(() => import('@/components/Component/StatusComponent.vue'));
const DueDetails = defineAsyncComponent(() => import('@/components/Section/DueDetails.vue'));
const ProductDetail = defineAsyncComponent(() => import('@/components/Section/ProductDetails.vue'));
const DeliveryDetails = defineAsyncComponent(() => import('@/components/Section/DeliveryDetails.vue'));
const ApproveDetails = defineAsyncComponent(() => import('@/components/Section/ApproveDetails.vue'));

const id = useRoute().query.id as string;
useSeoMeta({
    ogTitle: `${id} - ระบบติดตามการผลิต`,
    ogUrl: `https://order.somsritshirt.com/order?id=${id}`,
    twitterTitle: `${id} - ระบบติดตามการผลิต`,
});

definePageMeta({
    validate: (route) => !!route.query.id,
});

useHead({
    title: id.toUpperCase(),
});

// VARIABLE
const specSheetService = useSpecSheet();
const verificationModalOpenState = ref<boolean>(true);
const isLoading = ref<boolean>(true);
const isVerified = ref<boolean>(false);
const specSheet = ref<SpecSheet>({} as SpecSheet);
const { $toast } = useNuxtApp();
const validateForm = ref<ApproveForm>({
    sewCut: false,
    delivery: false,
    specSheet: false,
    amountlist: false,
    by: '',
    screen: false,
    pin: false,
    print: false,
});

// PROVIDE
provide('form', validateForm);

// FUNCTION
async function getSpecSheet(): Promise<SpecSheet | null> {
    const data = await specSheetService.get(id, {
        errorCallback: () => {
            $toast.error('เกิดปัญหาระหว่างค้นหาโปรเจกต์');
        },
    });

    return data;
}

async function reloadSpecSheet() {
    isLoading.value = true;
    specSheet.value = (await getSpecSheet()) as SpecSheet;
    isLoading.value = false;
}

async function loadSpecSheet() {
    isVerified.value = true;

    const data = await getSpecSheet();

    if (!data) throw createError({ statusCode: 404, statusMessage: `ไม่พบใบสเปคที่ไอดี ${id}` });
    specSheet.value = data;

    isLoading.value = false;
}

// COMPUTED
const isApprovedSpec = computed<boolean>(() => !!specSheet.value.stepData.customerApproved.done);
const haveScreenWork = computed<boolean>(() => specSheet.value.screenPoints.length > 0);
const havePinWork = computed<boolean>(() => specSheet.value.pinPoints.length > 0);
const havePrintWork = computed<boolean>(() => specSheet.value.printPoints.length > 0);

onMounted(async () => {
    verificationModalOpenState.value = true;
});
</script>
<template>
    <div>
        <NuxtLayout>
            <VerificationModal v-if="!isVerified" v-model:open="verificationModalOpenState" :spec-sheet-id="id" @verified="loadSpecSheet()" />

            <template v-else>
                <div v-if="!isLoading">
                    <h1 class="text-6xl text-primary-700 dark:text-primary-400 mb-1 font-bold">{{ specSheet.project?.name }}</h1>
                    <p class="text-3xl text-stone-700 dark:text-stone-400">{{ specSheet.productType }}</p>
                    <p class="mb-10 text-xl text-stone-700 dark:text-stone-400">ID: {{ specSheet.id }}</p>
                    <Status v-if="isApprovedSpec" :spec-sheet="specSheet" @reload-spec-sheet="reloadSpecSheet()">
                        <div class="mb-5">
                            <p class="text-lg dark:text-stone-300">
                                อนุมัติใบสเปคเมื่อ {{ formatDate(specSheet.stepData.customerApproved.at || '') }} โดย {{ specSheet.stepData.customerApproved.by }}
                            </p>
                        </div>
                    </Status>

                    <DueDetails :spec-sheet="specSheet" class="mb-10" />
                    <DeliveryDetails :approved="isApprovedSpec" :spec-sheet="specSheet" class="mb-10" />
                    <ProductDetail :approved="isApprovedSpec" :spec-sheet="specSheet" class="mb-10" />
                    <AmountDetails :approved="isApprovedSpec" :spec-sheet="specSheet" class="mb-10" />
                    <ScreenDetails v-if="haveScreenWork" :approved="isApprovedSpec" :spec-sheet="specSheet" class="mb-10" />
                    <PinDetails v-if="havePinWork" :approved="isApprovedSpec" :spec-sheet="specSheet" class="mb-10" />
                    <PrintDetails v-if="havePrintWork" :approved="isApprovedSpec" :spec-sheet="specSheet" class="mb-10" />
                    <ApproveDetails v-if="!isApprovedSpec" :spec-sheet="specSheet" @approve="reloadSpecSheet()" />
                </div>
                <LoadingScreen v-else />
            </template>
        </NuxtLayout>
    </div>
</template>
<style>
.required::after {
    color: red;
    content: '*';
}
</style>
